import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import './App.css'; // Make sure this line is present
import Header from './components/Header';
import Login from './components/Login';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Sidebar from './components/Sidebar'; // Assuming Sidebar is imported from the correct location
import Inbox from './components/Inbox';
import Patients from './components/Patients';
import Doctors from './components/Doctors'; // Add this import
import Visits from './components/Visits'; // Add this import

const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? element : null;
};

const AppContent: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initialize sidebar as open
  const { user } = useAuth();

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="App">
      <Header toggleSidebar={toggleSidebar} />
      {user && (
        <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <main
        className={`pt-16 transition-all duration-300 ease-in-out ${
          user
            ? sidebarOpen
              ? 'md:ml-64'
              : 'md:ml-20'
            : ''
        }`}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/inbox" element={<ProtectedRoute element={<Inbox />} />} />
          <Route path="/patients" element={<ProtectedRoute element={<Patients />} />} />
          <Route path="/doctors" element={<ProtectedRoute element={<Doctors />} />} />
          <Route path="/visits" element={<ProtectedRoute element={<Visits />} />} /> {/* Add this route */}
          <Route path="*" element={<Navigate to="/inbox" />} />
        </Routes>
      </main>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;