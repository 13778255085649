import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
} from 'firebase/firestore';

interface Visit {
  id: string;
  patientName: string;
  doctorName: string;
  date: string;
  time: string;
  notes: string;
}

const Visits: React.FC = () => {
  const [visits, setVisits] = useState<Visit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const visitsPerPage = 50;
  const [totalVisits, setTotalVisits] = useState<number>(0);

  useEffect(() => {
    const initialize = async () => {
      const coll = collection(db, 'visits');
      const snapshot = await getCountFromServer(coll);
      const total = snapshot.data().count;
      setTotalVisits(total);

      fetchVisits(1);
    };
    initialize();
  }, []);

  const fetchVisits = async (pageNumber: number) => {
    setLoading(true);
    try {
      let q;
      const visitsRef = collection(db, 'visits');
      const offset = (pageNumber - 1) * visitsPerPage;

      if (pageNumber === 1) {
        q = query(visitsRef, orderBy('date', 'desc'), limit(visitsPerPage));
      } else {
        const previousPageQuery = query(
          visitsRef,
          orderBy('date', 'desc'),
          limit(offset)
        );
        const previousPageSnapshot = await getDocs(previousPageQuery);
        const lastDoc =
          previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];

        q = query(
          visitsRef,
          orderBy('date', 'desc'),
          startAfter(lastDoc),
          limit(visitsPerPage)
        );
      }

      const querySnapshot = await getDocs(q);
      const fetchedVisits: Visit[] = [];
      querySnapshot.forEach((doc) => {
        fetchedVisits.push({ id: doc.id, ...doc.data() } as Visit);
      });

      setVisits(fetchedVisits);
      setCurrentPage(pageNumber);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error('Error fetching visits:', error);
      setError('Failed to fetch visits. Please try again later.');
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const totalPages = Math.ceil(totalVisits / visitsPerPage);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Visits</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">ID</th>
              <th className="py-3 px-6 text-left">Patient Name</th>
              <th className="py-3 px-6 text-left">Doctor Name</th>
              <th className="py-3 px-6 text-left">Date</th>
              <th className="py-3 px-6 text-left">Time</th>
              <th className="py-3 px-6 text-left">Notes</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {visits.map((visit) => (
              <tr
                key={visit.id}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {visit.id}
                </td>
                <td className="py-3 px-6 text-left">{visit.patientName}</td>
                <td className="py-3 px-6 text-left">{visit.doctorName}</td>
                <td className="py-3 px-6 text-left">{visit.date}</td>
                <td className="py-3 px-6 text-left">{visit.time}</td>
                <td className="py-3 px-6 text-left">{visit.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => fetchVisits(index + 1)}
            className={`px-3 py-1 mx-1 ${
              currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'
            } rounded`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Visits;